import { keyBy } from 'lodash'
import { useMemo } from 'react'

import useIsAdmin from '~/auth/hooks/useIsAdmin'
import type { TimeRange } from '~/data/time/types'
import { useCurrentOrgId } from '~/store/slices/currentOrg'

import type {
  CmsChargerFaultsGetFaultsArgs,
  CmsTimespanGetHistoricalStatusesArgs,
  GetChargersArgs,
  GetChargingTransactionsArgs
} from '../client'
import {
  useCmsChargerFaultsGetFaultsQuery,
  useCmsTimespanGetHistoricalStatusesQuery,
  useGetChargerConnectorsQuery,
  useGetChargerQuery,
  useGetChargersQuery,
  useGetChargingTransactionsQuery
} from '../client'
import type { UsePollingOptions, UseQueryOptions } from './polling'
import { usePolling } from './polling'
import type { MaybeDateRange } from './types'
import { useCurrentUser } from './users'
import { combineSkip, useValidatedDateRange } from './utils'

export function useChargerSiteIdsFilter(siteIds?: string[]) {
  const { synopUser } = useCurrentUser()
  const isAdmin = useIsAdmin()
  return useMemo(() => {
    if (siteIds?.length) return siteIds
    if (isAdmin) return undefined
    if (!synopUser?.sites || synopUser.sites.length === 0) return undefined
    return synopUser.sites ?? undefined
  }, [synopUser, isAdmin, siteIds])
}

/******************************************************************************
 * GET /chargers
 ******************************************************************************/
export function usePolledChargers(args?: GetChargersArgs, options?: UsePollingOptions) {
  const siteIds = useChargerSiteIdsFilter(args?.siteIds)

  const result = usePolling(
    useGetChargersQuery,
    { page: 0, count: 2000, ...args, siteIds },
    combineSkip(false, options)
  )
  return { chargers: result.data, ...result }
}

export function usePolledChargersAtSite(
  siteId?: string,
  args?: Omit<GetChargersArgs, 'siteIds'>,
  options?: UsePollingOptions
) {
  return usePolledChargers({ siteIds: siteId ? [siteId] : [], ...args }, combineSkip(!siteId, options))
}

export function useChargersAtSite(siteId?: string, args?: Omit<GetChargersArgs, 'siteIds'>, options?: UseQueryOptions) {
  return usePolledChargersAtSite(siteId, args, { ...options, intervalSeconds: 0 })
}

export function usePolledChargersForOrg(
  orgId: string,
  args?: Omit<GetChargersArgs, 'organizationId'>,
  options?: UsePollingOptions
) {
  return usePolledChargers({ organizationId: [orgId], ...args }, combineSkip(!orgId, options))
}

export function useChargersForOrg(orgId: string, args?: Omit<GetChargersArgs, 'organizationId'>) {
  return usePolledChargersForOrg(orgId, args, { intervalSeconds: 0 })
}

export function useChargersForCurrentOrg(args?: Omit<GetChargersArgs, 'organizationId'>) {
  const currentOrgId = useCurrentOrgId()
  return useChargersForOrg(currentOrgId, args)
}

export function useOrgChargersMap(
  orgId?: string,
  args?: Omit<GetChargersArgs, 'organizationId'>,
  options?: UsePollingOptions
) {
  const currentOrgId = useCurrentOrgId()
  const { isSuccess, isLoading, chargers } = usePolledChargersForOrg(orgId ?? currentOrgId, args, options)
  const orgChargers = useMemo(() => keyBy(chargers?.items ?? [], (c) => c.chargerId.toUpperCase()), [chargers])

  return {
    orgChargers,
    isLoading,
    isSuccess,
    lookup: (chargerId?: string) => orgChargers[chargerId ?? '']
  }
}

/******************************************************************************
 * GET /chargers/{chargerId}
 ******************************************************************************/
export function usePolledCharger(chargerId = '', options?: UsePollingOptions) {
  const result = usePolling(useGetChargerQuery, { chargerId }, combineSkip(!chargerId, options))

  // Ensure the charger returned from the hook matches the ID passed in. This is needed when the ID
  // changes or is cleared.
  const charger = result.data?.chargerId === chargerId ? result.data : undefined
  return { charger, ...result }
}

export function useCharger(chargerId?: string, options?: UseQueryOptions) {
  return usePolledCharger(chargerId, { ...options, intervalSeconds: 0 })
}

/******************************************************************************
 * GET /chargers/{chargerId}/connectors
 ******************************************************************************/
export function usePolledConnectors(chargerId: string = '', options?: UsePollingOptions) {
  const result = usePolling(useGetChargerConnectorsQuery, { chargerId }, combineSkip(!chargerId, options))
  return { ...result, connectors: result.data }
}

export function useConnectors(chargerId?: string) {
  return usePolledConnectors(chargerId, { intervalSeconds: 0 })
}

/******************************************************************************
 * GET /chargers/faults
 ******************************************************************************/
export type ChargerFaultsArgs = Omit<CmsChargerFaultsGetFaultsArgs, 'occurredAfter' | 'occurredBefore'>
export function usePolledChargerFaults(
  args?: ChargerFaultsArgs,
  dateRange?: MaybeDateRange,
  options?: UsePollingOptions
) {
  const { from, to } = useValidatedDateRange(dateRange)
  const siteIds = useChargerSiteIdsFilter(args?.siteIds)
  return usePolling(
    useCmsChargerFaultsGetFaultsQuery,
    { page: 0, count: 1000, occurredAfter: from.time, occurredBefore: to.time, ...args, siteIds },
    combineSkip(!to.valid || !from.valid, options)
  )
}

export function useChargerFaults(args?: ChargerFaultsArgs, dateRange?: MaybeDateRange) {
  return usePolledChargerFaults(args, dateRange, { intervalSeconds: 0 })
}

/******************************************************************************
 * GET /chargers/timespan/statuses
 ******************************************************************************/
export type HistoricalStatusesArgs = Omit<CmsTimespanGetHistoricalStatusesArgs, 'fromTs' | 'toTs'>

export function usePolledHistoricalStatuses(
  args?: HistoricalStatusesArgs,
  dateRange?: MaybeDateRange,
  options?: UsePollingOptions
) {
  const { from, to } = useValidatedDateRange(dateRange)
  const siteIds = useChargerSiteIdsFilter(args?.siteIds)
  const result = usePolling(
    useCmsTimespanGetHistoricalStatusesQuery,
    { fromTs: from.time, toTs: to.time, ...args, siteIds },
    combineSkip(!to.valid || !from.valid, options)
  )

  return { statusSummary: result.data, ...result }
}

export function useHistoricalStatuses(args?: HistoricalStatusesArgs, dateRange?: MaybeDateRange) {
  return usePolledHistoricalStatuses(args, dateRange, { intervalSeconds: 0 })
}

/******************************************************************************
 * GET /transactions
 ******************************************************************************/
type ChargingTransactionsArgs = Omit<GetChargingTransactionsArgs, 'chargerId'>
export function usePolledChargingTransactions(
  chargerId: string = '',
  args?: ChargingTransactionsArgs,
  options?: UsePollingOptions
) {
  const result = usePolling(useGetChargingTransactionsQuery, { chargerId, ...args }, combineSkip(!chargerId, options))
  return { ...result, transactions: result.data }
}

/**
 * Polls for charging transaction data on a 5 second basis.
 */
export function usePolledTransactions(args: GetChargingTransactionsArgs, timeRange: TimeRange) {
  const { from, to } = useValidatedDateRange(timeRange)
  const result = usePolling(
    useGetChargingTransactionsQuery,
    { count: 1000, startTimeTo: to.time, stopTimeFrom: from.time, status: ['inProgress', 'ended'], ...args },
    { skip: !to.valid || !from.valid, intervalSeconds: 5 }
  )

  return { transactions: result.data, ...result }
}
