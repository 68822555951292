import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  type SxProps,
  Tooltip,
  styled
} from '@mui/material'
import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'

import { useSettings } from '@core/hooks/useSettings'

import { useBannerOffset, useSideDrawerControls } from '~/store/slices/display'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))

export const sideDrawerWidth = 360

export function ContentShiftingDrawer({ children }: PropsWithChildren) {
  const { settings, saveSettings } = useSettings()
  const bannerOffset = useBannerOffset()
  const { isOpen, closeDrawer } = useSideDrawerControls()

  // Whenever the drawer is opened, close the nav drawer
  useEffect(() => {
    if (isOpen) {
      saveSettings({ ...settings, navCollapsed: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  // Close the drawer when the component unmounts
  useEffect(
    () => () => {
      closeDrawer()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Drawer
      sx={{
        width: sideDrawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sideDrawerWidth
        }
      }}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      {/* Consumes the same vertical space as the banner, offsetting the remaining drawer content */}
      <Box height={bannerOffset} flexShrink={0} />

      <DrawerHeader>
        <IconButton onClick={closeDrawer} size="small">
          <Close />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {children}
    </Drawer>
  )
}

const StyledContentWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  width: open ? `calc(100% - ${sideDrawerWidth}px)` : '100%',
  [theme.breakpoints.only('xs')]: { width: '100%' },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen
  })
}))

export function ContentWrapper({ children, sx }: PropsWithChildren<{ sx?: SxProps }>) {
  const { isOpen } = useSideDrawerControls()
  return (
    <StyledContentWrapper open={isOpen} sx={sx}>
      {children}
    </StyledContentWrapper>
  )
}

type DrawerListItemProps = {
  icon: React.ReactNode
  label: string
  onClick: () => void
  disabledTooltip?: string
  disabled?: boolean
}

export function DrawerListItem({ icon, label, onClick, disabled, disabledTooltip }: DrawerListItemProps) {
  const handleClick = disabled ? undefined : onClick

  return (
    <Tooltip title={disabled && disabledTooltip}>
      <ListItem disablePadding divider>
        <ListItemButton onClick={handleClick} disabled={disabled}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  )
}

export function ToggleDrawerButton({ children }: PropsWithChildren) {
  const { openDrawer, closeDrawer, isOpen } = useSideDrawerControls()
  return (
    <Button variant="contained" onClick={isOpen ? closeDrawer : openDrawer}>
      {children}
    </Button>
  )
}
