import {
  AdminPanelSettingsOutlined,
  AssessmentOutlined,
  AttachMoneyOutlined,
  BoltOutlined,
  CodeOutlined,
  HomeOutlined,
  LocalShippingOutlined,
  LocationOnOutlined,
  TuneOutlined
} from '@mui/icons-material'
import { Box } from '@mui/material'
import { compact } from 'lodash'
import { useMemo } from 'react'
import type { VerticalNavItemsType } from 'src/@core/layouts/types'

import { useCurrentOrg } from '~/data/api/hooks/organizations'

import routeDirectory from '../routes'

const { chargers, dashboard, settings, sites, vehicles } = routeDirectory

const adminOnly = {
  action: 'manage',
  subject: 'admin'
}

const iconBox = <Box sx={{ width: 14 }} />
const homeChargingLink = {
  title: 'Home Charging',
  icon: iconBox,
  path: settings.homeCharging.root,
  ...adminOnly
}

const workplaceChargingLink = {
  title: 'Workplace Charging',
  icon: iconBox,
  path: settings.workplaceCharging,
  ...adminOnly
}

const fleetBillingLink = {
  title: 'Fleet Billing',
  icon: iconBox,
  path: settings.fleetBilling,
  ...adminOnly
}

const publicChargingLink = {
  title: 'Public Charging',
  icon: iconBox,
  path: settings.publicCharging,
  ...adminOnly
}

const costExplorerLink = {
  title: 'Cost Explorer',
  icon: iconBox,
  path: settings.costExplorer,
  ...adminOnly
}

export default function useNavItems(): VerticalNavItemsType {
  const { org: currentOrganization } = useCurrentOrg()

  const { hasCostExplorer, reimbursementsStatus, fleetBillingStatus, workplaceChargingStatus, publicChargingStatus } =
    currentOrganization ?? {}

  const billingItems = useMemo<VerticalNavItemsType>(() => {
    const showHomeCharging = ['ENABLED', 'DISABLED'].includes(reimbursementsStatus ?? '')
    const showWorkplaceCharging = ['ENABLED', 'DISABLED'].includes(workplaceChargingStatus ?? '')
    const showPublicCharging = ['ENABLED', 'DISABLED'].includes(publicChargingStatus ?? '')
    const showFleetBilling = ['ENABLED', 'DISABLED'].includes(fleetBillingStatus ?? '')
    // If neither of the billing features are turned on, don't show the billing section
    if (!showHomeCharging && !showWorkplaceCharging && !showFleetBilling && !hasCostExplorer && !showPublicCharging)
      return []

    return [
      {
        title: 'Billing',
        icon: <AttachMoneyOutlined />,
        ...adminOnly,
        children: compact([
          showHomeCharging && homeChargingLink,
          showWorkplaceCharging && workplaceChargingLink,
          showFleetBilling && fleetBillingLink,
          hasCostExplorer && costExplorerLink,
          showPublicCharging && publicChargingLink
        ])
      }
    ]
  }, [fleetBillingStatus, hasCostExplorer, publicChargingStatus, reimbursementsStatus, workplaceChargingStatus])

  return [
    {
      title: 'Dashboard',
      icon: <HomeOutlined />,
      path: dashboard.root
    },
    {
      title: 'Vehicles',
      icon: <LocalShippingOutlined />,
      path: vehicles.root
    },
    {
      title: 'Sites',
      icon: <LocationOnOutlined />,
      path: sites.root
    },
    {
      title: 'Chargers',
      icon: <BoltOutlined />,
      path: chargers.root
    },
    {
      title: 'Reports',
      icon: <AssessmentOutlined />,
      path: routeDirectory.reports
    },
    ...billingItems,
    {
      sectionTitle: 'Platform'
    },
    {
      title: 'Admin',
      icon: <AdminPanelSettingsOutlined />,
      subject: 'admin_settings',
      action: 'read',
      children: [
        {
          title: 'Settings',
          path: settings.orgManagement,
          icon: iconBox,
          ...adminOnly
        },
        {
          title: 'Organizations',
          path: settings.orgs,
          icon: iconBox,
          ...adminOnly
        },
        {
          title: 'Users',
          path: settings.users,
          icon: iconBox,
          subject: 'read',
          action: 'user_settings'
        }
      ]
    },
    {
      title: 'Manage',
      icon: <TuneOutlined />,
      children: [
        {
          title: 'Locations of Interest',
          path: settings.locations,
          icon: iconBox
        },
        {
          title: 'Sites',
          path: settings.sites.root,
          icon: iconBox
        },
        {
          title: 'Fleets',
          path: settings.fleets,
          icon: iconBox
        },
        {
          title: 'Chargers',
          path: settings.chargers.root,
          icon: iconBox
        },
        {
          title: 'Vehicles',
          path: settings.vehicles.root,
          icon: iconBox
        },
        {
          title: 'Alerts',
          path: settings.alerts,
          icon: iconBox,
          ...adminOnly
        }
      ]
    },
    {
      title: 'Developer',
      icon: <CodeOutlined />,
      children: [
        {
          title: 'Documentation',
          path: 'https://docs.synop.ai',
          icon: iconBox,
          openInNewTab: true
        },
        {
          title: 'Usage',
          path: settings.apiKeys,
          icon: iconBox
        }
      ]
    }
  ]
}
